
      module.exports = {
        functional: true,
        render(_h, _vm) {
          const { _c, _v, data, children = [] } = _vm;

          const {
            class: classNames,
            staticClass,
            style,
            staticStyle,
            attrs = {},
            ...rest
          } = data;

          return _c(
            'svg',
            {
              class: [classNames,staticClass],
              style: [style,staticStyle],
              attrs: Object.assign({"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 448 512"}, attrs),
              ...rest,
            },
            children.concat([_c('path',{attrs:{"fill":"currentColor","d":"M128.09 220H424a24 24 0 0124 24v24a24 24 0 01-24 24H128.09l-35.66-36z","opacity":".4"}}),_c('path',{attrs:{"fill":"currentColor","d":"M142.56 409L7 273.5v-.06a25.23 25.23 0 010-34.84l.06-.06 135.5-135.49a24 24 0 0133.94 0l17 17a24 24 0 010 33.94L92.43 256 193.5 358a24 24 0 010 33.94l-17 17a24 24 0 01-33.94.06z"}})])
          )
        }
      }
    